<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>Data Pembayaran</h5>
      </template>

      <b-form @submit.prevent="handleFilterPembayaran">
        <div class="row">
          <div class="col-lg-3">
            <b-form-group label="No Invoice" label-for="filterInput">
              <b-form-input v-model="filter.no_invoice" name="filterInvoice" id="filterInput" type="search"
                placeholder="No Invoice"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <b-form-group label="Filter tanggal">
              <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true" :showDropdowns="true"
                :autoApply="true" @select="handleSelectDate">
              </date-range-picker>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <b-form-group label="Supplier">
              <multiselect v-model="filter.id_supplier" :options="supplier" :internal-search="true" :allow-empty="true"
                deselect-label="" select-label="" :close-on-select="true" label="nama" track-by="id"
                placeholder="pilih supplier" />
            </b-form-group>
          </div>
          <div class="col d-flex align-items-center">
            <b-button type="submit" class="mt-2" variant="pgiBtn" name="resetTableBtn"
              :disabled="isLoading">Filter</b-button>
            <b-button type="button" class="mt-2 ml-2" variant="pgiBtn" name="resetTableBtn" :disabled="isLoading"
              @click="onReset()">Reset</b-button>
          </div>
        </div>
      </b-form>



      <b-table bordered hover show-empty :busy="isLoading" :items="pembelian" :fields="fields" :perPage="perPage"
        responsive class="mb-3 mt-3">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading data...</strong>
          </div>
        </template>
        <template v-slot:empty>
          Tidak ada data Pembelian.
        </template>
        <template v-slot:cell(status_approval)="row">
          <b-badge variant="primary">
            Approval {{ row.item.total_approval || 0 }} / {{ row.item.status_approval || 0 }}
          </b-badge>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge variant="secondary" v-if="row.item.status == 0">Belum Proses</b-badge>
          <b-badge variant="primary" v-if="row.item.status == 1">Sudah Diproses</b-badge>
          <b-badge variant="info" class="text-white" v-if="row.item.status == 2">Sudah Dibayar</b-badge>
          <b-badge variant="success" v-if="row.item.status == 3">Diterima</b-badge>
          <b-badge variant="danger" v-if="row.item.status == 4">Dibatalkan</b-badge>
          <b-badge variant="danger" v-if="row.item.status == 5">Barang Selisih</b-badge>
          <b-badge variant="warning" v-if="row.item.status == 6">Permintaan Void</b-badge>
        </template>
        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-link :to="`${$route.path}/${row.item.id}/detail`">
              <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Detail" name="pembelianViewBtn">
                <i class="fa fa-info"></i>
              </b-button>
            </b-link>
          </div>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <span class="ml-2">{{ totalViewText }}</span>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

  </div>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import axiosClient from '../../../config/api'
export default {
  components: { DateRangePicker },
  name: 'pembelian',
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      isSubmitting: false,
      isLoading: false,
      activeId: null,
      input: {
        show: 10
      },
      form: {
        tanggal: "",
        id_supplier: null,
        pic: "0999",
        ongkir: "",
        file: null,
        keterangan: ""
      },

      dateRange: { startDate: null, endDate: null },
      filter: {
        no_invoice: null,
        from: null,
        to: null,
        id_supplier: null
      },

      fields: [
        { key: "no_invoice", label: "No Invoice" },
        { key: "tanggal", label: "Tanggal" },
        { key: "supplier", label: "Supplier" },
        { key: "totalUnit", label: "Total Unit" },
        { key: "totalPembelian", label: "Total Pembelian" },
        { key: "status", label: "Status" },
        { key: "status_approval", label: "Approval" },
        { key: "action", label: "Aksi" },
      ],
      pembelian: null,
      supplier: [],
      supplierValue: [],

      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: '',
      size: '',
      from: '',
      limit: '',
      query: "",
    }
  },
  methods: {

    //----------------- Fungsi Inisialisasi -----------------

    init() {
      this.initStatus = 0
      let getSuplier = this.$axios.get("api/supplier/all").then(response => {
        let datas = response.data
        for (var data of datas.data) {
          this.supplier.push({
            id: data.id,
            nama: data.nama
          })
        }
      })
      Promise.all([getSuplier]).then(() => {
        this.initStatus = 1
        let msg = this.$helper.getFlash()
        this.$helper.toastSucc(this, msg)
        // this.toggleBusy()
      })
        .catch(error => {
          console.log(error)
          this.initStatus = -1
        })
    },
    handleFilterPembayaran() {
      const query = {
        no_invoice: this.filter.no_invoice,
        id_supplier: this.filter.id_supplier ? this.filter.id_supplier.id : null,
        from: this.filter.from,
        to: this.filter.to,
      }
      this.getDataPembelian(query)
    },
    handleSelectDate(value) {
      const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
      const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
      this.filter.from = startDate
      this.filter.to = endDate
    },

    async getDataPembelian(query) {
      try {
        this.isLoading = true
        const response = await axiosClient({
          url: "transaksi/pembelian?flag=1",
          params: { ...query }
        })

        const datas = response.data.data
        this.pembelian = datas.data.map(data => {
          return {
            id: data.id,
            no_invoice: data.no_invoice,
            tanggal: data.tanggal,
            supplier: data.supplier ? data.supplier.nama : "-",
            totalUnit: data.total_unit,
            totalPembelian: this.$helper.rupiah(data.total_pembelian),
            status: data.flag,
            status_approval: data.status_approval,
            total_approval: data.total_approval,
          }
        })

        this.perPage = datas.per_page
        this.size = this.perPage
        this.from = datas.from
        this.limit = datas.to
        this.updateTotalItem(datas.total)
        let msg = this.$helper.getFlash()
        this.$helper.toastSucc(this, msg)
      } catch (error) {

      }
      finally {
        this.isLoading = false
      }
    },

    prepare(modalToShow) {


      if (this.form.id_supplier == null) {
        return this.$helper.toastErr(this, "Mohon mengisi Supplier!")
      }

      if (this.form.ongkir == "") {
        return this.$helper.toastErr(this, "Mohon mengisi Ongkir!")
      }

      this.$bvModal.show(modalToShow)

    },

    //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

    onPageChange() {
      if (this.isLoading == false) {
        this.getDataPembelian({
          ...this.query,
          page: this.currentPage
        })
      }

    },

    async onReset() {
      this.dateRange = { startDate: null, endDate: null },
        this.filter = {
          no_invoice: null,
          from: null,
          to: null,
          id_supplier: null
        },
        await this.getDataPembelian()
    },

    toggleBusy() {
      if (this.isBusy == true) this.isBusy = false
      else this.isBusy = true
    },

    updateTotalItem(total) {
      this.perPage = this.size
      this.rows = total
      if (this.limit > this.rows) limit = this.rows
      this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
    },
    inputPembelian() {
      this.$router.push({ path: `${this.$route.path}/input` });
    },
  },
  watch: {

    //----------------- Pantau Jenis Barang -----------------

    "form.ongkir": function (newValue) {
      let cleanValue = this.$helper.changeToValue(newValue);
      const result = cleanValue.replace(/(?!^-)[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => this.form.ongkir = result);
    },
  },

  created() {
    this.init()
    this.getDataPembelian()
  }
}
</script>
